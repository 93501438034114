
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    isPostGrid: {
      type: Boolean,
      dafault: false
    }
  },
  data() {
    return {
      marked: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.marked = this.isMarkedAsFavourite(this.id);
    });
  },
  methods: {
    toggleFavourite(e) {
      if (this.marked) {
        this.removeFromFavourite(this.id);
      } else {
        this.addToFavourite(this.id);
      }
      this.marked = !this.marked;
      e.preventDefault();
    }
  }
};
