
export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    buttonLink: {
      type: String,
      default: ''
    },
    buttonLabel: {
      type: String,
      default: ''
    }
  }
};
