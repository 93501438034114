import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve the post related to a post by category
 * Example query:
 *
 *
 **[_type=="blog" &&
  live==true &&
  (dateTime(now()) >= dateTime(publicationDate)) &&
  __i18n_lang=="de_CH" &&
  postCategories[]._ref match ["1860c253-9c63-4478-a200-f5e067ed5515"] &&
  !(_id in path("drafts.**"))][0..11] {
    _id, author->{name}, publicationDate, title, url, teaserImage
  }
 */

export default function getBlogPostsByCategories(locale, categories) {
  const type = '_type == "blog"';
  const live = 'live == true';
  const beforePublicationDate = '(dateTime(now()) >= dateTime(publicationDate))';
  const categoriesQuery = `postCategories[]._ref match [${categories
    .map((cat) => `"${cat._id}"`)}]`;
  const lang = `__i18n_lang=="${locale}_CH"`;
  const notInDraft = '!(_id in path("drafts.**"))';
  const objectProjection = `_id, author->{name}, publicationDate, title, url, readingTime,
  "teaserImage": {"metadata": teaserImage.refImage->{"asset": upload.asset->,
  "crop": upload.crop, "hotspot": upload.hotspot},
  "alt": teaserImage.alt},
  postCategories[]->{"label": entry.key}`;

  return groq`*[
    ${type} &&
    ${live} &&
    ${lang} &&
    ${categoriesQuery} &&
    ${beforePublicationDate} &&
    ${notInDraft}
  ] | order(publicationDate desc) {${objectProjection}}`;
}
