
import { mapGetters } from 'vuex';
export default {
  props: {
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      locale: 'navigation/locale',
      isFavoriteFilterActivated: 'ui/isFavoriteFilterActivated'
    })
  },
  mounted() {
    if (this.$route.query.favorites === 'activated') {
      this.$store.commit('ui/favoriteFilterActivated', true);
    } else {
      this.$store.commit('ui/favoriteFilterActivated', false);
    }
  },
  methods: {
    toggleFavorites() {
      if (this.$route.query.favorites === 'activated') {
        this.$router.push({ path: '/', hash: '#filters', query: { favorites: 'deactivated' } });
        this.$store.commit('ui/favoriteFilterActivated', false);
      } else {
        this.$router.push({ path: '/', hash: '#filters', query: { favorites: 'activated' } });
        this.$store.commit('ui/favoriteFilterActivated', true);
      }
    }
  }
};
