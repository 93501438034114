import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve the post related to a post by category
 * Example query:
 *
 *
 **[_id in ["22ae86b6-f137-494c-b3ce-af435e6ebb69", "480cb413-7ade-4ff1-8d0b-ac479acdcd64"]]
{_id, author->{name}, publicationDate, title, url, teaserImage}
 */

export default function getFavouritesBlogPostQuery(ids) {
  const _ids = `_id in [${ids.map((id) => `"${id}"`)}] | order(publicationDate desc)`;
  const objectProjection = `_id, author->{name}, publicationDate, title, url,
  "teaserImage": {"metadata": teaserImage.refImage->{"asset": upload.asset->,
  "crop": upload.crop, "hotspot": upload.hotspot},
  "alt": teaserImage.alt}`;
  return groq`*[${_ids}] {${objectProjection}}`;
}
