import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve the post related to a post by category
 * Example query:
 *
 *
 **[_type=="blog" &&
 (dateTime(now()) >= dateTime(publicationDate)) &&
  __i18n_lang=="de_CH" &&
  !(_id in path("drafts.**"))
 ] | order(publicationDate desc)
{_id, author->{name}, publicationDate, title, url, teaserImage}
 */

export default function getOrderedBlogPosts(locale, order = 'desc') {
  const type = '_type == "blog"';
  const live = 'live == true';
  const beforePublicationDate = '(dateTime(now()) >= dateTime(publicationDate))';
  const lang = `__i18n_lang=="${locale}_CH"`;
  const notInDraft = '!(_id in path("drafts.**"))';
  const objectProjection = `_id, author->{name}, publicationDate, title, url,
  "teaserImage": {"metadata": teaserImage.refImage->{"asset": upload.asset->,
  "crop": upload.crop, "hotspot": upload.hotspot},
  "alt": teaserImage.alt}, postCategories[]->{"label": entry.key}`;

  return groq`*[
    ${type} &&
    ${live} &&
    ${lang} &&
    ${beforePublicationDate} &&
    ${notInDraft}
  ] | order(publicationDate ${order}) {${objectProjection}}`;
}
