import { render, staticRenderFns } from "./MarkAsFavourite.vue?vue&type=template&id=91da6a8e&scoped=true"
import script from "./MarkAsFavourite.vue?vue&type=script&lang=js"
export * from "./MarkAsFavourite.vue?vue&type=script&lang=js"
import style0 from "./MarkAsFavourite.vue?vue&type=style&index=0&id=91da6a8e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91da6a8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/elements/Button.vue').default})
