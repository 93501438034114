
import { mapGetters } from 'vuex';

export default {
  props: {
    post: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      locale: 'navigation/locale'
    }),
    postDate() {
      if (!this.locale) {
        return;
      }

      return this.$convertDateToString(this.post.publicationDate, this.locale);
    }
  },
  methods: {
    convertDateToString(date, locale) {
      const toParse = new Date(date);
      const day = toParse.getDate();
      const month = toParse.toLocaleString(locale.replace('_CH', ''), { month: 'long' });
      const year = toParse.getFullYear();
      return `${day}. ${month} ${year}`;
    }
  }
};
