import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve the post related to a post by category
 * Example query:
 *
 *
 **[_type=="blog" &&
  title match "*7*" &&
  __i18n_lang=="de_CH" &&
 !(_id in path("drafts.**"))] {
    _id, author->{name}, publicationDate, title, url, teaserImage
  }
 */

export default function getBlogPostsByTitle(locale, title) {
  const type = '_type == "blog"';
  const live = 'live == true';
  const beforePublicationDate = '(dateTime(now()) >= dateTime(publicationDate))';
  const lang = `__i18n_lang=="${locale}_CH"`;
  const notInDraft = '!(_id in path("drafts.**"))';
  const searchInContent = `| score(
    boost(title match "*${title}*", 1),
     boost(pt::text(content.sections[].text) match "*${title}*", 1)
    )[ _score > 0 ]`;
  const objectProjection = `_id, author->{name}, publicationDate, title, url, readingTime,
  teaserImage, postCategories[]->{"label": entry.key}`;

  return groq`*[
    ${type} &&
    ${live} &&
    ${lang} &&
    ${beforePublicationDate} &&
    ${notInDraft}
  ] ${searchInContent} {${objectProjection}}`;
}
