import { groq } from '@nuxtjs/sanity';
/**
 * In this query, we retrieve the post related to a post by category
 * Example query:
 *
 *
 **[_type=="blog" &&
  live==true &&
  (dateTime(now()) >= dateTime(publicationDate)) &&
  __i18n_lang=="de_CH" &&
  !(_id in path("drafts.**"))][0..11] {
    _id, author->{name}, publicationDate, title, url, teaserImage
  }
 */

export default function getBlogPosts(locale, postCount, featuredPostId) {
  const type = '_type == "blog"';
  const live = 'live == true';
  const beforePublicationDate = '(dateTime(now()) >= dateTime(publicationDate))';
  const lang = `__i18n_lang=="${locale}_CH"`;
  const excludeFeaturedPost = `_id != "${featuredPostId}"`;
  const notInDraft = '!(_id in path("drafts.**"))';
  const objectProjection = `_id, author->{name}, publicationDate, title, url, readingTime,
  "teaserImage": {"metadata": teaserImage.refImage->{"asset": upload.asset->,
  "crop": upload.crop, "hotspot": upload.hotspot},
  "alt": teaserImage.alt},
  postCategories[]->{"label": entry.key}, excerpt`;
  const firstLoadPagination = '0..10'; // for Sanity, this means 11 posts
  const pagination = postCount ?? firstLoadPagination;

  return groq`*[
    ${type} &&
    ${live} &&
    ${lang} &&
    ${featuredPostId ? `${excludeFeaturedPost} &&` : ''}
    ${beforePublicationDate} &&
    ${notInDraft}
  ] | order(publicationDate desc)[${pagination}] {${objectProjection}}`;
}
